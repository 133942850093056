import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { API_URL, CACHE_TIME, sanitize, prepareImages } from '../../index';
import { ContactContext } from '../App';
import { useQuery } from 'react-query';

import IndexHeaderDesktop from "../IndexComponents/IndexHeaderDesktop";
import IndexHeaderMobile from "../IndexComponents/IndexHeaderMobile";
import ModalContext from '../MakeAppointmentModal/ModalProvider';
import IndexPackageOffers from '../IndexComponents/IndexPackageOffers';
import Timer from '../IndexComponents/Timer';
import Swiper from '../IndexComponents/Swiper';
import { Helmet } from 'react-helmet-async';
import FieldsList from '../Fields/FieldsList';

import ReviewSwiper from '../PageComponents/ReviewSwiper';
import DoctorPreview from '../Doctors/DoctorsPreview';
import Footer from '../PageComponents/Footer';
import * as DOMPurify from 'dompurify';
import ErrorWrapper from "../PageComponents/ErrorWrapper";



const fieldsURL = "pages/medical-fields/";
const pageURL = "pages/index/";
const doctorsURL = 'doctors/';



const IndexPage = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [pageData, setPageData] = useState({});
    const fieldData = useQuery('page-medical-fields', () => axios.get(API_URL + fieldsURL).then(response => response.data), { cacheTime: CACHE_TIME });
    const contacts = useContext(ContactContext);
    const [error, setError] = useState(false);
    const { modalOn, modalData } = useContext(ModalContext);
    const doctors = useQuery('doctors', () => axios.get(API_URL + doctorsURL).then(response => response.data), { cacheTime: CACHE_TIME });


    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(API_URL + pageURL).then(data => setPageData(data.data)).catch(err => setError(true));
    }, []);

    useEffect(() => {
        const updateWidth = () => {
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateWidth);
        updateWidth();
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    

    const onClick = () => {
        modalData("Прийом у лікаря", BUTTON_TEXT);
        modalOn();
    };

    return (
        <ErrorWrapper error={error}>
            <Helmet>
                {pageData.SEO_title && (<title>{sanitize(pageData.SEO_title)}</title>)}
                {pageData.SEO_desc && (<meta name="description" content={sanitize(pageData.SEO_desc)} data-react-helmet="true" />)}
                {pageData.SEO_keywords && (<meta name="keywords" content={sanitize(pageData.SEO_keywords)} data-react-helmet="true" />)}
                {pageData.SEO_title && (<meta property="og:title" content={sanitize(pageData.SEO_title)} data-react-helmet="true" />)}
                {pageData.SEO_desc && (<meta property="og:description" content={sanitize(pageData.SEO_desc)} data-react-helmet="true" />)}
            </Helmet>
            {width > 700 ? (<IndexHeaderDesktop pageData={pageData} contacts={contacts} />) : (<IndexHeaderMobile pageData={pageData} contacts={contacts} />)}
            <div className="frame last" style={{ maxWidth: "100%", overflow: "hidden" }} >
                <h1 className="main-header index-content-header">{pageData.title2 && pageData.title2.toUpperCase().split('|').join('\n')}</h1>
                <Timer />
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(pageData.text2)) }}></div>
                <h1 className="main-header">{fieldData.data && fieldData.data.title1 && fieldData.data.title1.toUpperCase().split('|').join('\n')}</h1>
                <FieldsList />
                <h1 className="main-header">ПАКЕТНІ ПРОПОЗИЦІЇ</h1>
                <IndexPackageOffers />
                <h1 className="main-header">НАШІ ФАХІВЦІ</h1>
                <div style={{ width: "98%", display: "flex", justifyContent: "flex-start", overflow: "hidden" }}>
                    <Swiper>
                        {doctors && doctors.data && doctors.data.map(doctor => (<DoctorPreview doctor={doctor} key={"doctor" + doctor.pk} />))}
                    </Swiper>
                </div>
                <ReviewSwiper />
                <div style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <button className="general-schedule-button" onClick={onClick}>{LOCAL_BUTTON_TEXT}</button>
                </div>
            </div>
            <Footer nested="0" />
        </ErrorWrapper>
    );
}

export default IndexPage;

export const LOCAL_BUTTON_TEXT = "ЗАПИСАТИСЯ НА ПРИЙОМ →";
export const BUTTON_TEXT = "ЗАПИСАТИСЯ НА ПРИЙОМ";
