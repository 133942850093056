import { useContext } from 'react';
import { prepareImages, phoneToCall } from '../../index';
import * as DOMPurify from 'dompurify';
import { Link } from "react-router-dom";
import './Footer.css';
import RouteUrl from '../RouteEnum';
import { ContactContext } from '../App';

const Footer = ({ nested }) => {
    const { addresses, phones, workhours, socialmedia } = useContext(ContactContext);

    const prefix = "../".repeat(nested);

    const ready = (listVariable) => {
        return (listVariable && (+(listVariable[0].pk) > 0));
    }

    return (
        <>
        <div className="footer-padding"></div>
        <div className="footer-container">
                <div className="footer-column" id="logo-column">
                <Link to={RouteUrl.index} style={{ color: "inherit" }} relative="path"><div className="footer-logo">&nbsp;</div></Link>
                    <div>
                        {ready(workhours) &&
                            workhours.map(workhour => {
                                return (
                                    <div key={"workhoursfooter" + workhour.pk} className="footer-workhours">
                                        <div className="footer-workhours-day">{workhour.title}:&nbsp;&nbsp;&nbsp;</div><div className="footer-workhours-time">{workhour.time}</div>
                                    </div>
                                )
                            })
                    }
                </div>
                <div className="footer-social-media">
                    {ready(socialmedia) &&
                            socialmedia.map(media => (<Link to={media.link} style={{ margin: "10px" }} key={"footer-" + media.name} target="_blank"><img src={media.icon} /></Link>))}
                </div>
            </div>

                <div className="footer-column" id="info-column">
                <h4 className="footer-header">Інформація</h4>
                <div className="footer-link"><Link to={prefix +RouteUrl.medicalFields} style={{ color: "inherit" }} relative="path">Напрями</Link></div>
                <div className="footer-link"><Link to={prefix +RouteUrl.doctors} style={{ color: "inherit" }} relative="path">Фахівці</Link></div>
                <div className="footer-link"><Link to={prefix +RouteUrl.procedures} style={{ color: "inherit" }} relative="path">Методики та процедури</Link></div>
                <div className="footer-link"><Link to={prefix +RouteUrl.prices} style={{ color: "inherit" }} relative="path">Ціни</Link></div>
                <div className="footer-link"><Link to={prefix +RouteUrl.offers} style={{ color: "inherit" }} relative="path">Акції</Link></div>
            </div>

                <div className="footer-column" id="extra-column">
                    <h4 className="footer-header">Додатково</h4>
                    <div className="footer-link"><Link to={prefix + RouteUrl.blog} style={{ color: "inherit" }} relative="path">Блог</Link></div>
                <div className="footer-link"><Link to={prefix + RouteUrl.giftVoucher} style={{ color: "inherit" }} relative="path">Подарунковий сертифікат</Link></div>
                <div className="footer-link"><Link to={prefix + RouteUrl.licence} style={{ color: "inherit" }} relative="path">Ліцензія клініки</Link></div>
                <div className="footer-link"><Link to={prefix +RouteUrl.contacts} style={{ color: "inherit" }} relative="path">Як дістатися?</Link></div>
            </div>

                <div className="footer-column" id="contacts-column">
                <h4 className="footer-header">Контакти</h4>
                <div className = "footer-contacts-data">
                    <div className="address-symbol">&nbsp;</div>
                    <div className="footer-contacts-text">
                        {ready(addresses) &&
                                addresses.map(address => (
                                    <a href={"http://maps.google.com/?q=" + address.address.replace(/<[^]*?>/g, '')} target="_blank">
                                        <div key={"addressfooter" + address.pk} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(address.address)) }}></div>
                                    </a>
                            ))}
                    </div>
                </div>
                <div className="footer-contacts-data">
                    <div className="phone2-symbol">&nbsp;</div>
                    <div className="footer-contacts-text">
                        {ready(phones) &&
                                phones.map(phone => (
                                    <div key={"phonefooter" + phone.pk}>
                                        <a href={"tel:" + phoneToCall(phone.number)} style={{ color: "inherit" }}>
                                            {phone.number}
                                        </a>
                                    </div>
                            ))}
                    </div>
                </div>
                </div>
            <div className="mobile-padding"></div>
        </div>
    </>)
}

export default Footer;