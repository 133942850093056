import { useState, useEffect, useRef, Fragment } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import './MakeAppointmentModal.css';
import { API_URL, sanitize} from '../../index';

const makeAppointmentURL = "make-appointment/";

const HIDDEN = "hidden";

const modalInit = {
    modal: HIDDEN,
    form: HIDDEN,
    overlay: HIDDEN,
    success: HIDDEN,
    error: HIDDEN
}

const MakeAppointmentModal = ({ title, buttonText, visible, turnOff }) => {
    const [hiddenStr, setHiddenStr] = useState(modalInit);
    const ref = useRef(null);
    const { register, handleSubmit } = useForm();
    const [formError, setFormError] = useState(false);

    useEffect(() => {
        const modalHide = {
            modal: HIDDEN,
            form: HIDDEN,
            overlay: HIDDEN,
            success: HIDDEN,
            error: HIDDEN
        } 
        if (visible) {
            modalHide.modal = "";
            modalHide.form = "";
            modalHide.overlay = "";
        }
        setHiddenStr(modalHide);
    }, [visible]);

    useEffect(() => {
        const handleClick = (event) => {
            turnOff();
        }
        const element = ref.current;
        element.addEventListener('click', handleClick);

        return () => {
            element.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => { }, [formError]);

    const onSubmit = async (data) => {
        setFormError(false);
        try {
            const fullData = {
                name: sanitize(data.firstName),
                surname: sanitize(data.surname),
                phone: sanitize(data.phoneNumber),
                service: sanitize(title)
            }
            if ((!fullData.name && !fullData.surname) || !fullData.phone) {
                setFormError(true);
            }
            else {
                axios.defaults.xsrfCookieName = 'csrftoken';
                axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
                await axios.post(API_URL + makeAppointmentURL, fullData, { headers: { 'Content-Type': 'multipart/form-data' } });
                const modalNew = {
                    modal: "",
                    form: HIDDEN,
                    overlay: "",
                    success: "",
                    error: HIDDEN
                }
                setHiddenStr(modalNew);
            }
        } catch {
            const modalNew = {
                modal: "", 
                form: HIDDEN,
                overlay: "",
                success: HIDDEN,
                error: ""
            }
            setHiddenStr(modalNew);
        }        
    }


    return (
        <Fragment>
            <section className={"modal " + hiddenStr.modal}>
                <div className={"form-response " + hiddenStr.success}>Дякуємо! Вашу заявку прийнято, з вами зв'яжуться у найближчий час</div>
                <div className={"form-response " + hiddenStr.error}>Щось пішло не так... Будь ласка, спробуйте ще раз або зателефонуйте нам</div>
                <form className={hiddenStr.form} onSubmit={handleSubmit(onSubmit)}>
                    <h4>Онлайн запис на прийом до фахівця </h4>
                    <div style={{ display: "grid" }}>
                        <label htmlFor="firstName">Ім'я</label>
                        <input type="text" id="firstName" {...register("firstName")} placeholder="Вкажіть ваше ім'я" />                        

                        <label htmlFor="surname">Прізвище</label>
                        <input type="text" id="surname" {...register("surname")} placeholder="Вкажіть ваше прізвище" />

                        <label htmlFor="phoneNumber">Номер телефону</label>
                        <input type="number" id="phoneNumber" {...register("phoneNumber")} placeholder="Вкажіть ваш номер телефону" />

                        <label htmlFor="service">Послуга</label>
                        <input type="text" id="service" value={title} disabled className="disabled-input" />

                        {formError && (<div style={{ color: "red", fontWeight: "800" }}>Будь ласка, введіть телефон та ім'я для зв'язку</div>)}

                        <div className="btn-div"><input type="submit" value={buttonText} className="btn" /></div>
                        
                    </div>
                </form>
            </section>
            <div className={"overlay " + hiddenStr.overlay} ref={ref}></div>
        </Fragment>
        );
}

export default MakeAppointmentModal;

